.mySwiperNewGallery {
    background-color: black; 
    height: 100%;
    padding: 8px;
  }
  
  button:hover {
    background: transparent;
    color: white;
    border: 1px solid white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
.mainn{
  position: relative;
  background-color: black;
  height: 60vh;
  width: 50%;
  margin:auto;
 margin-bottom: 10px;
}
.btn1{
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 99;
}
.btn2{
  position: absolute;
  bottom: 10px;
  right: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 99;
  
}
.imgg{
  display: block;
  object-fit: cover;
  height: 100%;
  width: 50% !important;
}

@media screen and (max-width:1025px) {
  .mainn{
    position: relative;
    background-color: black;
    height: 70vh;
    width: 80%;
    margin:auto;
   margin-bottom: 10px;
  }
  .btn1{
    position: absolute;
    bottom: 30px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 99;
  }
  .btn2{
    position: absolute;
    bottom: 10px;
    right: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 99;
    
  }
}

  @media screen and (max-width:769px) {
    .mainn{
      position: relative;
      background-color: black;
      height: 70vh;
      width: 80%;
      margin:auto;
     margin-bottom: 10px;
    }
    .btn1{
      position: absolute;
      bottom: 30px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      z-index: 99;
    }
    .btn2{
      position: absolute;
      bottom: 10px;
      right: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      z-index: 99;
      
    }
  }

  @media screen and (max-width:525px) {
    .mainn{
      position: relative;
      background-color: black;
      height: 70vh;
      width: 90%;
      margin:auto;
     margin-bottom: 10px;
    }
    .btn1{
      position: absolute;
      bottom: 13px;
      right: 110px;
      display: flex;
      align-items: center;
      z-index: 99;
    }
    .btn2{
      position: absolute;
      bottom: 0px;
      right: 167px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      z-index: 99;
    }
    .mySwiperNewGallery {
      background-color: black; 
      height: 100%;
      padding: 0px;
    }
    .imgg{
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100% !important;
    }
   
  }
  @media screen and (max-width:  377px) {
    .imgg{
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100% !important;
    }
    .btn2{
      position: absolute;
      bottom: 0px;
      right: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      z-index: 99;
    }
    .mainn{
      position: relative;
      background-color: black;
      height: 70vh;
      width: 90%;
      margin:auto;
     margin-bottom: 10px;
    }
    .btn1{
      position: absolute;
      bottom: 16px;
      right: 69px;
      display: flex;
      align-items: center;
      z-index: 99;
    }
    .mySwiperNewGallery {
      background-color: black; 
      height: 100%;
      padding: 0px;
    }
  }
