@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Navbar Toggler Styling */
.navbar-toggler {
    background-color: white !important;
    border: none;
    margin-top: 3px;
}

.navbar-toggler{
background-color: white !important;
}
.navbar-toggler-icon{
background-color: white !important;
}

.bg-body-tertiary{
    padding: 0px 13px;
}

/* Navigation Links */
.Nav_Link {
    padding: 10px 20px ;
    background-color: #D32D0B;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: white !important;

}

.Nav_Linkplan {
    padding: 10px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    transition: color 0.3s ease;
}

/* Hover and Active States */
.Nav_Link:hover, .Nav_Linkplan:hover {
    background-color: #D32D0B;
    box-shadow: 0px 4px 10px rgba(211, 45, 11, 0.4);
    color: white !important;

}

.Nav_Linkplan.active {
    background-color: #D32D0B;
    color: white !important;
}
.NavBarItems{
    margin-left: 10px;
}

/* Medium Layout: 1367px. */
@media only screen and (min-width: 1025px) and (max-width: 1367px) {
    .Nav_Link, .Nav_Linkplan {
        padding: 8px 15px;
        font-size: 14px;
        height: 65px;
    }

    .navbar-toggler {
        padding: 4px 8px;
        color: white;
    }

    .navbar-toggler-icon {
        width: 22px;
    }
}




