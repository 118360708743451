.breakfast-slider {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default for small screens */
  gap: 20px;
  margin: 0 auto;
  justify-items: center; /* Center content when only one item */
}

@media (min-width: 768px) {
  .breakfast-slider {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on medium and larger screens */
    justify-items: start; /* Align images left on larger screens */
  }
}

.breakfast-slider img {
  width: 100%; /* Ensure the image scales correctly */
  height: auto;
  border-radius: 8px;
}
