.swiper-slide333 {
  background-position: center;
  background-size: cover;
  width: 600px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.UpcommingSlider {
    width: 100%;
    height: auto ;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0px;
}