/* Gallery Grid Layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1100px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }
}

/* Individual Gallery Item */
.ddddaaa{
  position: relative; /* To position overlay inside gallery item */
  cursor: pointer;
  transition: transform 0.2s;
}

.ddddaaa img {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
}

/* Hover effect: Scale image and add overlay */
.ddddaaa:hover {
  transform: scale(1.05);
}

.ddddaaa:hover .gallery-overlay {
  opacity: 1; /* Show the overlay when hovered */
}

/* Overlay Styling */
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.gallery-overlay .view-button {
  padding: 10px 20px;
  background-color: rgba(73, 70, 70, 0.8);
  color: black;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s;
}

.gallery-overlay .view-button:hover {
  background-color: rgba(255, 255, 255, 1);
  color: black;

}

/* Darken the backdrop of the Modal */
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important; /* Dark overlay */
}

/* Change the modal content background color */
.ant-modal-content {
  background-color: #5e5e5e3d !important; /* Dark background for modal */
  color: white; /* Change text color to white for contrast */
}

/* Optional: Change the modal header background */
.ant-modal-header {
  background-color: #444 !important; /* Dark header */
  color: white; /* Ensure header text is visible */
}

/* Optional: Change modal footer background */
.ant-modal-footer {
  background-color: #444 !important; /* Dark footer */
}

.ant-modal-close-x {
  color: white; /* Ensure close button text is visible */
}
