
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.newfont{
  font-family: "pacifica-w00-condensed", "fantasy";
  font-weight: 400;
  font-style: normal;
  font-size:30px !important;
  line-height: 39px !important;
  text-transform: uppercase;
}
.custom-ui {
    font-family: 'Open Sans', sans-serif;
    padding-top: 3%;
    /* max-height: 86vh; */
  } 
  .headingbanner{
    font-size: 40px;
    color: black;
    font-weight: 700;
  }
  
  .subtitle{
    font-size: 20px;
    color: black;
    font-weight: bolder;
  }
  @media screen and (max-width:767px) {
    .custom-ui {
        margin: 19%;
      } 
      .headingbanner{
        font-size: 30px;
        color: black;
      }
      .subtitle{
        font-size: 18px;
        color: black;
      }
  }

  @media screen and (max-width:525px) {
    .custom-ui {
        margin-top: 10%;
      } 
      .headingbanner{
        font-size: 25px;
        color: black;
      }
      .subtitle{
        font-size: 8px;
        color: black;
      }
  }
  @media screen and (max-width:  377px) {
    .custom-ui {
        margin-top: 10%;
      } 
      .headingbanner{
        font-size: 25px;
        line-height: 30px;
    }
    .subtitle{
      font-size: 10px;
      color: black;
    }
  }





  .contact-info {
    font-size: 22px;
    font-weight: bold;
    color: black;
  }
  
  button {
    background-color: black;
    border: none;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #d32d0b;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  body{
position: relative;
  }

  body:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #d0edee49;
      opacity: .9;
      z-index: -1;
  }
  
  .pogochotabheem{
background: #ceecf8b7;
opacity: .8;
z-index: -1;
margin-top: 100px;
  }